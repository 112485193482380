import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

const MissingMarketplaceFieldsPage = ({ data }) => {
  const { allMarketplaceFields: result } = data
  const { nodes } = result

  const entries = nodes.reduce((acc, { pagePath, marketplaceFields }) => {
    marketplaceFields.forEach(marketplaceField => {
      const { field, value, ...identifiers } = marketplaceField
      if (value !== null) {
        return acc
      }
      if (!acc[marketplaceField.field]) {
        acc[marketplaceField.field] = []
      }
      acc[marketplaceField.field].push({ pagePath, ...identifiers })
    })
    return acc
  }, {})

  if (Object.keys(entries).length === 0) {
    // NOTE: text is used by notification: https://github.com/fundera/nw-smb-gatsby/blob/9c81519676a6ab6ffafefd4c2f163e0e5d0790a3/.github/workflows/prod-nw-smb.yml#L184
    return <div>No missing marketplace fields</div>
  }

  return (
    <div>
      <h1>Missing marketplace fields:</h1>
      <pre>
        {JSON.stringify({ fields: entries, total: entries.length }, null, 2)}
      </pre>
    </div>
  )
}

MissingMarketplaceFieldsPage.propTypes = {
  data: PropTypes.shape({
    allMarketplaceFields: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          pagePath: PropTypes.string.isRequired,
          marketplaceFields: PropTypes.arrayOf(
            PropTypes.shape({
              id: PropTypes.string.isRequired,
              entityType: PropTypes.string.isRequired,
              productType: PropTypes.string.isRequired,
              field: PropTypes.string.isRequired,
              value: PropTypes.string,
            })
          ).isRequired,
        })
      ).isRequired,
    }),
  }),
}

export default MissingMarketplaceFieldsPage

export const query = graphql`
  query MarketplaceFieldsQuery {
    allMarketplaceFields {
      nodes {
        pagePath
        marketplaceFields {
          id
          entityType
          productType
          field
          value
        }
      }
    }
  }
`
